<template>
<div class="row">
    <div class="col-md-4">
      <card>
          <h2>{{ toolGroup.name }}</h2>
          <img v-if="this.toolGroup.toolGroupImageBytes" class="toolImage" :src="'data:image/png;base64,' + this.toolGroup.toolGroupImageBytes" alt="tool alt text" />
          <h3 style="margin-bottom: 0px;" v-if="this.totalUsed">{{$t('toolGroups.usage') + ": "+ this.totalUsed }}</h3>
          <h3 style="margin-bottom: 0px;" v-if="this.toolGroup.dailyPrice">{{$t('tools.dailyPrice') + ": "+ this.toolGroup.dailyPrice }} Kr.</h3>

      </card>
      <div v-if="this.userRole !== 'User'" class="row">
        <div v-if="!this.isMobile && this.userRole !== 'User'" class="col-md-12">
          <base-button block type="standard" @click="printQRCode">{{$t('tools.printQR')}}</base-button>
        </div>
      </div>
    </div>
    <div class="col-md-8" v-if="this.userRole !== 'User'">
      <card card-body-classes="table-full-width" :title="$t('toolGroups.reserved')">
        <div>
          <!-- Desktop Table -->
          <el-table v-if="!isMobile" :data="reservations">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>

          </el-table>
          <!-- Mobile Table -->
          <el-table v-else :data="reservations">

            <el-table-column :label="$t('common.info')" min-width="190">
              <template slot-scope="scope">
                <div>
                  <div><strong>{{$t('common.user')}}:</strong> {{ scope.row.user }}</div>
                  <div><strong>{{$t('toolGroups.quantity')}}:</strong> {{ scope.row.quantity }}</div>
                  <div><strong>{{$t('toolGroups.reservationType')}}:</strong> {{ scope.row.reservationLoanRent }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
    <div class="row" v-show="false">
      <card type="toolqr">
        <p class="card-text"></p>
        <div class="qr-print-container">
          <qrcode-vue
            render-as="svg"
            :value="qrValue"
            :size="qrPrintSize"
            level="H"
            background="#ffffff"
            foreground="#000000"
            ref="printqrcode"
          >
          </qrcode-vue>
          <h3>{{ this.toolGroup.name }}</h3>
        </div>
      </card>
    </div>
</div>
<!--  -->
</template>

<script>
import api from "../../services/api";  // Import your API service
import { Table, TableColumn, Select, Option } from 'element-ui';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import swal from 'sweetalert2';
import QrcodeVue from 'qrcode.vue';  // Import QrcodeVue from qrcode.vue
import config from "@/config";




export default {
components: {
    QrcodeVue,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
},
data() {
    return {
    toolGroup: {},
    reservations:[],
    toolGroupId: 0,
    totalUsed:'',
    tableColumns: [
      {
        prop: 'user',
        label: this.$t('common.user'),
        minWidth: 260
      },
      {
        prop: 'quantity',
        label: this.$t('toolGroups.quantity'),
        minWidth: 100
      },
      {
        prop: 'reservationLoanRent',
        label: this.$t('toolGroups.reservationType'),
        minWidth: 200
      }
    ],
    isMobile: window.innerWidth <= 768,
    userRole: '',
    qrDisplaySize: 128,
    qrPrintSize: 256,
  };
},
created() {
  this.toolGroupId = this.$route.params.toolGroupId
  const token = Cookies.get('accessToken');
  const decodedToken = jwtDecode(token);
  this.userRole = decodedToken.role
  this.fetchData();
},
computed: {
  qrValue() {
    // Combine the base URL with the tool ID to create the full URL
    let qrUrl = `${config.baseUrl}/#/toolGroup/${this.toolGroup.id}`;
    return qrUrl;
  },
},
methods: {
    fetchData()
    {
      this.reservations = [];
      let toolGroupUrl = `/ToolGroup/${this.toolGroupId}`;
      console.log(toolGroupUrl)
      api.get(toolGroupUrl)
          .then(response => {
            console.log(response)
          this.toolGroup = response.data;
          this.reservations = this.toolGroup.toolGroupReservations.filter(item => item.isReserved === true).map(item => {

            let reservationLoanRent = ""
            if(item.rented === true)
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Rented") + " - " + item.externalUser
            }
            else if(item.loaned === true)
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Loaned") + " - " + item.externalUser
            }
            else
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Reserved")
            }
            return {
              user: item.user.firstName + " " + item.user.lastName,
              quantity: item.quantity,
              reservationLoanRent: reservationLoanRent,
              reservedDate: item
            };
          });

          this.totalUsed = 0
          if(this.toolGroup.toolGroupReservations)
          {
            this.toolGroup.toolGroupReservations.forEach((res, index) => {
              if(res.isReserved === true)
              {
                this.totalUsed = this.totalUsed + Number(res.quantity)
              }
            });
          }

          if(this.toolGroup.toolGroupProperties.filter(p => p.key === 'totalCount'))
          {
            this.totalUsed = String(this.totalUsed) + "/" + this.toolGroup.toolGroupProperties.filter(p => p.key === 'totalCount')[0].value
          }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
    },
    printQRCode() {
      var tmp = document.createDocumentFragment(),
      printme = document.getElementsByClassName('qr-print-container')[0].cloneNode(true);
      while(document.body.firstChild) {
          // move elements into the temporary space
          tmp.appendChild(document.body.firstChild);
      }
      // put the cloned printable thing back, and print
      document.body.appendChild(printme);
      window.print();

      while(document.body.firstChild) {
          // empty the body again (remove the clone)
          document.body.removeChild(document.body.firstChild);
      }
      // re-add the temporary fragment back into the page, restoring initial state
      document.body.appendChild(tmp);
    },
    navigateToUserProfile(userId) {
      this.$router.push({ name: 'User', params: { userId: userId } });
    },
    getReservationLoanRentTranslation(reservationLoanRent)
    {
       switch (reservationLoanRent) {
            case "Loaned":
              return this.$t('tools.loaned')
            case "Rented":
              return this.$t('tools.rented')
            case "Reserved":
              return this.$t('tools.reserved')
            default:
              return 'N/A'
          }
    },

},
};
</script>
<style>
.red-row {
  background-color: rgb(214, 123, 123)!important;
}
.black-button {
  color: black !important;
}
.clickable-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.clickable-link:hover {
  color: #0056b3;
}
.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
