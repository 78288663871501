<template>
  <div class="customer-settings">
    <div class="row">
      <div class="col-md-6">
        <card>
          <h4 slot="header" class="card-title">{{$t('customerSettings.companyPhoto')}}</h4>
          <div class="image-containercustomersettings">
            <image-upload
              class="col-md-6 ml-auto mr-auto"
              @change="onImageChange"
              @upload="onUpload"
              :src="'data:image/png;base64,' + customer.logoBytes"
              :base64Image="customer.logoBytes"
              :select-text="$t('image.selectImage')"/>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card class="stacked-form" title="Stacked Form">
          <h4 slot="header" class="card-title">{{$t('customerSettings.newSetting')}}</h4>
          <form @submit.prevent="onSubmit">
            <div>
              <base-input
                :label="$t('customerSettings.key')"
                type="key"
                :placeholder="$t('customerSettings.enterKey')"
                v-model="keyValue.key"
              >
              </base-input>
              <base-input
                :label="$t('customerSettings.value')"
                type="value"
                :placeholder="$t('customerSettings.enterValue')"
                v-model="keyValue.value"
              >
              </base-input>
              <div class="form-group">
                <base-checkbox v-model="keyValue.encrypted">{{$t('customerSettings.encrypt')}}</base-checkbox>
              </div>
                <div class="col-sm d-flex justify-content-end">
                  <base-button class="mt-3" native-type="submit" type="primary">{{$t('common.submit')}}</base-button>
                </div>
            </div>
          </form>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{$t('customerSettings.settings')}}</h4>
          <el-table :data="tableData">
            <el-table-column
              v-if="!isMobile"
              min-width="150"
              sortable
              :label="$t('customerSettings.key')"
              property="key"
            ></el-table-column>
            <el-table-column
              v-if="!isMobile"
              min-width="150"
              sortable
              :label="$t('customerSettings.value')"
            >
            <template slot-scope="scope">
              <div v-if="!scope.row.encrypted">{{ scope.row.value }}</div>
              <div v-if="scope.row.encrypted"><em>{{$t('customerSettings.encrypted')}}</em></div>
            </template>
          </el-table-column>
            <el-table-column v-if="isMobile" :label="$t('common.info')" min-width="220">
              <template slot-scope="scope">
                <div>
                  <div><strong>{{$t('customerSettings.key')}}:</strong> {{ scope.row.key }}</div>
                  <div v-if="!scope.row.encrypted"><strong>{{$t('customerSettings.value')}}:</strong> {{ scope.row.value }}</div>
                  <div v-if="scope.row.encrypted"><strong>{{$t('customerSettings.value')}}:</strong> <em>{{$t('customerSettings.encrypted')}}</em></div>
                </div>
              </template>
            </el-table-column>
             <el-table-column :min-width="135" align="right" label="">
            <div slot-scope="props">
              <base-button
                @click.native.stop="handleDelete(props.row.key)"
                class="remove btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageUpload, Card, BaseInput, BaseButton, BaseCheckbox } from 'src/components/index';
import { Table, TableColumn } from 'element-ui';
import api from "../../services/api";  // Import your API service
import swal from 'sweetalert2';

export default {
  components: {
    ImageUpload,
    Card,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      images: {
        regular: null,
      },
      keyValue: {
        key: '',
        value: '',
        encrypted: false
      },
      tableData: [],
      customer: {
        name: '',
        logoBytes: null
      }
    };
  },
  methods: {
    onImageChange(file) {
      this.images.regular = file;
    },
    handleDelete(key){
         swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteCustomerSetting(key);
          swal.fire({
            title: this.$t('delete.deleted'),
            text: `${this.$t('delete.youDeleted')} ${key}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        }
      });
    },
    deleteCustomerSetting(key){
      let deleteUrl = `/Customers/CustomerSettings/` + key
      api.delete(deleteUrl)
      .then(response => {
          this.tableData = this.tableData.filter(s => s.key !== key);
      })
          .catch(error => {
          console.error('API request error:', error);
      });
    },
    fetchData() {
      let url = "Customers/CustomerSettings";  // Replace with your API endpoint
      api.get(url)
        .then(response => {
          this.tableData = response.data.map(item => {
            return {
              key: item.key,
              value: item.value,
              encrypted: item.encrypted
            };
          });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    fetchCustomer() {
      let url = "Customers/Me";  // Replace with your API endpoint
      api.get(url)
        .then(response => {
          this.customer.name = response.data.value.name;
          if (response.data.value.logoBytes) {
            this.customer.logoBytes = response.data.value.logoBytes;
            // Convert base64 to Blob
            let byteCharacters = atob(this.customer.logoBytes);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: "image/png"});
            // Create URL representing the image
            this.images.regular = URL.createObjectURL(blob);
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    onUpload() {
      let reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result.replace(/^data:.+;base64,/, '');
        let url = "Customers/Update/Logo";  // Your POST endpoint
        let body = {
          logoBytes: base64String
        };
        api.put(url, body)
          .then(response => {
            // Handle successful upload
            this.$notify({
              message: this.$t('customerSettings.logoUpdated'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 2000,
            });
            this.fetchCustomer();
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToUpdate'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
      };
      reader.readAsDataURL(this.images.regular);
    },
    onSubmit() {
      let url = "/Customers/CustomerSettings";  // Your POST endpoint
      let body = {
        settings: [
          {
            key: this.keyValue.key,
            value: this.keyValue.value,
            encrypted: this.keyValue.encrypted
          }
        ]
      };
      api.post(url, body)
        .then(response => {
          this.$notify({
            message: `${this.$t('customerSettings.key')} ${this.keyValue.key} ${this.$t('customerSettings.createdUpdated')}`,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
          this.fetchData();  // Refresh the data after successful submission
          this.keyValue= {
            key: '',
            value: '',
            encrypted: false
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToCreate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
  },
  created() {
    this.fetchData();  // Fetch data when the component is created
    this.fetchCustomer();  // Fetch customer data when the component is created
  }
};
</script>

<style>
.image-upload-page .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.image-containercustomersettings {
  display: flex;
  justify-content: space-between;
}

.image-containercustomersettings > img,
.image-containercustomersettings > image-upload {
  width: 49%;  /* Adjust as needed */
}
</style>
