<!-- ButtonGroup.vue -->
<template>
  <div>
    <div class="btn-group btn-group-toggle below-dropdown" data-toggle="buttons">
      <label
        v-for="(option, index) in myTablesCategories"
        :key="option.name"
        class="btn btn-sm btn-simple"
        :class="[{ active: activeIndex === index}, selectClass]"
        :id="index"
      >
        <input
          type="radio"
          @click="initTable(index)"
          name="options"
          autocomplete="off"
          :checked="activeIndex === index"
        />
        <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
        <span class="d-block d-sm-none">
          <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myTablesCategories: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      required: true,
    },
    selectClass: {
      type: String,
      required: true,
    },
    initTable: {
      type: Function,
      required: true,
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
