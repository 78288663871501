<template>
<div class="row-md-12">
    <ScannerInputForm :onSubmit="unreserve"></ScannerInputForm>
    <div v-if="toolsToDisplay === true " class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width" :title="$t('tools.lastReturnedTools')">
          <el-table :data="lastReturnedTools">
              <el-table-column
                prop="image"
                :label="$t('common.image')"
                width="80"
              >
                <template slot-scope="scope">
                  <img :src="'data:image/jpeg;base64,' + scope.row.toolImageThumbnailBytes" alt="Tool Image" />
                  </template>
              </el-table-column>
              <el-table-column
                class="mobile-stack"
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"

              >
              </el-table-column>
            </el-table>
        </card>
      </div>
     </div>


</div>
</template>
<script>
import api from "../../services/api"
import { ScannerInputForm } from "@/components";
import { Table, TableColumn, Select, Option } from 'element-ui';

export default {
components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScannerInputForm
},
data() {
    return {
      toolsToDisplay: false,
      lastReturnedTools:[],
        tableColumns: [
        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 100
        },
        {
          prop: 'description',
          label: this.$t('common.description'),
          minWidth: 200
        },
        {
          prop: 'brandName',
          label: this.$t('tools.brand'),
          minWidth: 100
        }
      ],
    };
},
computed: {

},
methods: {
  unreserve(tools) {
    this.lastReturnedTools = tools
    this.toolsToDisplay = true;
    tools.forEach(tool => {
      this.callApi(tool);
    });
  },
  async callApi(tool) {
    try {
      if(this.validateUuid(tool.id))
      {
        let unreserveUrl = `/Tool/Unreserve/${tool.id}`;
        const response = await api.put(unreserveUrl);
        this.$notify({
          message: this.$t('tools.successfulUnreserve'),
          icon: "tim-icons icon-check-2",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
        });
      }  else {
        this.$notify({
          message: this.$t('tools.invalidToolId') + ": " + tool.id,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
      }
    } catch (error) {
      this.$notify({
        message: this.$t('tools.toolReturnError'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
      console.error(error);
    }
  },
  validateUuid(uuid) {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(uuid);
  },
},
created() {
},
};
</script>
<style></style>
