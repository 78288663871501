<template>
  <div>
    <!-- Render the Desktop table -->
    <el-table :data="tableData" @row-click="handleRowClick" v-if="!isMobile" :row-class-name="allowClick ? 'clickable-row' : ''">
      <el-table-column
        v-for="column in filteredTableColumnsDesktop"
        :key="column.label"
        :width="column.width || null"
        :min-width="column.minWidth || null"
        :prop="column.prop"
        :label="column.label"
      >
        <template v-if="!column.compound" slot-scope="scope">
          <div v-if="column.isImage"><img :src="scope.row[column.prop]" alt="Image" /></div>
          <div v-else>{{ scope.row[column.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="showActions" :min-width="100" align="right" :label="$t('common.actions')">
        <div slot-scope="props">
          <base-button v-if="enableEdit" @click.native.stop="handleEdit(props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
            <i class="tim-icons icon-pencil"></i>
          </base-button>
          <base-button v-if="enableDelete" @click.native.stop="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon>
            <i class="tim-icons icon-simple-remove"></i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <!-- Render the Mobile table -->
    <el-table :data="tableData" @row-click="handleRowClick" v-if="isMobile">
      <el-table-column
        v-for="column in filteredTableColumnsMobile"
        :key="column.label"
        :width="column.mobileWidth || null"
        :min-width="column.mobileMinWidth || null"
        :prop="column.prop"
        :label="column.label"
      >
        <template v-if="column.isCompound || column.showOnMobile" slot-scope="scope">
          <img v-if="column.isImage" :src="scope.row[column.prop]" alt="Image" />
          <div v-if="!column.isCompound && !column.isImage">{{ scope.row[column.prop] }}</div>
          <div v-if="column.isCompound">
            <div v-for="(value, key) in column.compound" :key="key">
              <strong>{{ $t(key) }}:</strong> {{ scope.row[value] }}
            </div>
          </div>
          <div v-if="showActions && column.isCompound && !actionsInline" class="action-buttons">
              <base-button v-if="enableEdit" @click.native.stop="handleEdit(scope.$index, scope.row)" class="edit btn-link" type="warning" size="sm" icon>
                <i class="tim-icons icon-pencil"></i>
              </base-button>
              <base-button v-if="enableDelete" @click.native.stop="handleDelete(scope.$index, scope.row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="showActions && actionsInline" :width="setButtonWidth" align="right">
        <template slot-scope="scope">
          <div class="side-action-buttons">
            <base-button v-if="enableEdit" @click.native.stop="handleEdit(scope.$index, scope.row)" class="edit btn-link" type="warning" size="sm" icon>
              <i class="tim-icons icon-pencil"></i>
            </base-button>
            <base-button v-if="enableDelete" @click.native.stop="handleDelete(scope.$index, scope.row)" class="remove btn-link" type="danger" size="sm" icon>
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
export default {
	name: "el-base-table",
	data() {
		return {
			isMobile: window.innerWidth <= 768,
		};
	},
  computed: {
    filteredTableColumnsDesktop() {
      return this.tableColumns.filter(column => !column.compound);
    },
    filteredTableColumnsMobile() {
      return this.tableColumns.filter(column => column.compound || column.showOnMobile);
    },
    showActions() {
      return this.enableEdit || this.enableDelete
    },
    setButtonWidth() {
      return (this.enableEdit && this.enableDelete) ? 80 : 60
    }
  },
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn
	},
  created() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  props: {
    tableData: Array,
    tableColumns: Array,
    allowClick: Boolean,
    actionsInline: Boolean,
    enableEdit: Boolean,
    enableDelete: Boolean
  },
  methods: {
    handleRowClick(index, row) {
      this.$emit('row-click', index, row);
    },
    handleEdit(index, row) {
      this.$emit('edit', index, row);
    },
    handleDelete(index, row) {
      this.$emit('delete', index, row);
    },
		updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>
<style>
	.clickable-row {
		cursor: pointer;
	}
	.action-buttons {
		display: flex;
		justify-content: center;
	}
  .side-action-buttons {
		display: flex;
		justify-content: center;
    float: right;
	}
</style>
