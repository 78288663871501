<!-- eslint-disable vue/no-mutating-props -->
<template>
<card class="stacked-form" :title="computedTitle" :onCancel="cancel">
    <div class="row">
      <div class="col-md-6">
        <label class="col-md-3 col-form-label">{{$t('common.name')}} *</label>
        <div class="col-md-9">
          <base-input :placeholder="$t('common.name')" ref="nameInput" v-model=name> </base-input>
        </div>
        <label class="col-md-3 col-form-label">{{$t('toolGroups.totalCount')}} *</label>
        <div class="col-md-9">
          <base-input :placeholder="$t('toolGroups.totalCount')" ref="totalCount" v-model=totalCount> </base-input>
        </div>
        <label class="col-md-3 col-form-label">{{$t('toolGroups.dailyPrice')}} *</label>
        <div class="col-md-9">
          <base-input :placeholder="$t('toolGroups.dailyPrice')" ref="dailyPrice" v-model=dailyPrice> </base-input>
        </div>
      </div>
        <div class="col-md-6">
          <div class="col-md-9">
            <image-upload :showUploadButton="false" @change="handleFileUpload" :src="imageSrc" :select-text="$t('image.selectImage')" />
          </div>
        </div>


    </div>
    <div class="row">
    <div class="col-sm d-flex justify-content-end">
        <base-button @click="saveToolGroup" type="standard" fill>{{$t('common.submit')}}</base-button>
    </div>

    </div>
</card>
</template>
<script>
import api from "../../services/api"
import { ImageUpload } from 'src/components/index';


export default {
props: {
  editMode: {
    type: Boolean,
    required: true
  },
  nameProp: {
    type: String,
    required: false
  },
  totalCountProp: {
    type: String,
    required: false
  },
  dailyPriceProp: {
    type: String,
    required: false
  },
  toolGroupImageBytesProp: {
    type: String,
    required: false
  },
  idToUpdateProp: {
    type: Number,
    required: false
  }
},
components: {
    ImageUpload
},
data() {
    return {
    name: this.nameProp,
    totalCount: this.totalCountProp,
    dailyPrice: this.dailyPriceProp,
    toolGroupImageBytes: this.toolGroupImageBytesProp,
    idToUpdate: this.idToUpdateProp
    };
},
computed: {
  computedTitle() {
    return this.editMode ? this.$t('toolGroups.editToolGroup') : this.$t('toolGroups.createToolGroup');
  },
  imageSrc() {
      return this.toolGroupImageBytes ? 'data:image/png;base64,' + this.toolGroupImageBytes : '';
    }
},
methods: {
  cancel()
  {
    this.$emit('close');
  },
  async saveToolGroup() {
    if (this.validateInput()) {
      let requestBody = {};

      if(this.toolGroupImageBytes === '')
      {
        requestBody = {
          toolgrouptypeid: 2,
          name: this.name,
          dailyPrice: Number(this.dailyPrice),
          properties: [{key:'totalCount', value: this.totalCount}]
        };
      }
      else{
        requestBody = {
          toolgrouptypeid: 2,
          name: this.name,
          toolGroupImageBytes: this.toolGroupImageBytes,
          dailyPrice: Number(this.dailyPrice),
          properties: [{key:'totalCount', value: this.totalCount}]
        };
      }

      if(this.editMode)
      {
        const response = await api.put(`/ToolGroup/${this.idToUpdate}`, requestBody)
        .then(response =>
        {
          this.$notify({
            message: `${this.name} ${this.$t('common.updated')}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.$emit('refreshData');
          this.$emit('close');
        }).catch(error =>
        {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      }
      else
      {
        const response = await api.post('/ToolGroup', requestBody)
        .then(response =>
        {
          this.$notify({
            message: `${this.name} ${this.$t('common.created')}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.$emit('refreshData');
          this.$emit('close');
        }).catch(error =>
        {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToCreate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
        }
    }
  },
  handleFileUpload(file) {
    if(file){
      const reader = new FileReader();

      reader.onloadend = () => {
        let binary = '';
        const bytes = new Uint8Array(reader.result);
        bytes.forEach((byte) => binary += String.fromCharCode(byte));
        this.toolGroupImageBytes = btoa(binary);
      };
      reader.readAsArrayBuffer(file);
    }
    else{
      this.toolGroupImageBytes = ''
    }
  },
  validateInput() {
    let success = true;
      if (this.name === '') {
        this.$refs.nameInput.focus();
        this.$notify({
          message: this.$t('errors.nameEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.totalCount === '' || isNaN(this.totalCount)) {
        this.$refs.totalCount.focus();
        this.$notify({
          message: this.$t('errors.totalCountEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.dailyPrice !== '' && isNaN(this.dailyPrice)) {
        this.$refs.totalCount.focus();
        this.$notify({
          message: this.$t('errors.dailyPriceNotNumber'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolGroupImageBytes === '') {
        this.$notify({
          message: this.$t('errors.imageEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
  }
}
};
</script>
<style></style>
