<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="cardTitle">
          <template v-slot:header-extra v-if="isMobile">
            <div class="unreserve-tools">
              <QrScanner class="qr-scanner-unreserve" @decodedReserve="startReserve" @decodedReturn="unreserve" />
            </div>
          </template>
          <modal :show.sync="showReturnToolGroupModal" class="white-content">
            <card>
              <h4 class="card-title">{{$t('toolGroups.returnToolGroup')}}</h4>

              <div class="col-md-12">
                <div>

                  <el-table :data="toolGroupReturnTable" >
                    <el-table-column :label="$t('common.info')" min-width="150">
                      <template slot-scope="scope">
                        <div>
                          <div><strong>{{$t('toolGroups.reservationType')}}:</strong> {{ scope.row.toolGroupReservationType }}</div>
                          <div><strong>{{$t('toolGroups.quantity')}}:</strong> {{ scope.row.quantity }}</div>
                          <div><strong>{{$t('tools.reservation')}}:</strong> {{ scope.row.reservationDate }}</div>
                          <div><strong>{{$t('common.project')}}:</strong> {{ scope.row.project }}</div>

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="50">
                      <template slot-scope="scope">
                        <el-checkbox
                          v-model="selectedToolGroupToolsToReturnRows[scope.row.id]"
                          @change="handleSelection(scope.row.id, $event)"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                  <!-- button -->
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                      <base-button @click="unreserveToolGroup" type="standard" fill>{{$t('common.confirm')}}</base-button>
                  </div>
                </div>
              </div>
            </card>
          </modal>
          <modal :show.sync="showProjectPickModal" class="modal-scroll-fix white-content">
            <card>
              <h4 class="card-title">{{$t('tools.pickProject')}}</h4>

              <div class="col-md-12">
                  <div>
                    <div
                      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <base-input>
                        <el-input
                          class="mb-3 search-input"
                          clearable
                          prefix-icon="el-icon-search"
                          :placeholder="$t('common.searchRecords')"
                          v-model="searchQuery"
                          aria-controls="datatables"
                          @keyup.native="searchProjects"
                          @clear="handleClearSearchProjects"
                        >
                        </el-input>
                      </base-input>

                    </div>

                  <!-- Mobile Table -->
                  <el-table :data="projects" >
                    <el-table-column :label="$t('common.info')" min-width="150">
                      <template slot-scope="scope">
                        <div>
                          <div><strong>{{$t('projects.key')}}:</strong> {{ scope.row.key }}</div>
                          <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column  width="50">
                      <template slot-scope="scope" >
                        <el-radio  v-model="selectedProject" :label="scope.row.id"></el-radio>
                      </template>
                    </el-table-column>
                  </el-table>

                  </div>
                  <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        {{ $t('pagination.showing') }} {{ projectsFrom + 1 }} {{ $t('pagination.to') }} {{ projectsTo }} {{ $t('pagination.of') }} {{ projectsTotal }} {{ $t('pagination.entries') }}
                      </p>
                    </div>
                    <base-pagination
                      @input="projectsUpdatePage"
                      class="pagination-no-border"
                      v-model="projectsPagination.currentPage"
                      :per-page="projectsPagination.perPage"
                      :total="projectsTotal"
                    >
                    </base-pagination>
                  </div>
                  <!-- button -->
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                      <base-button @click="reserveTool" type="standard" fill>{{$t('common.confirm')}}</base-button>
                  </div>
                </div>
              </div>
            </card>
          </modal>

          <modal :show.sync="showUpdateReservationDaysModal" class="white-content">
            <h3 class="card-title">{{$t('tools.setNumberOfDays')}}</h3>
            <div class="row">
              <div class="col-12">
                <base-input
                :label="$t('tools.numberOfDays')"
                :placeholder="$t('tools.updatedNumberOfDays')"
                v-model="updateDays"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <modal :show.sync="showReserveToolsInToolGroupModal" class="white-content">
            <h3 style="margin-bottom: 0px;" class="card-title">{{$t('toolGroups.reserveToolInToolGroup')}}</h3>
            <h4 >{{$t('toolGroups.available')}}: {{availableToolsInToolGroup}}</h4>
            <div class="row">
              <div class="col-6">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('toolGroups.quantity')}} *</label>
                <base-input
                :placeholder="$t('toolGroups.quantity')"
                v-model="toolGroupQuantity"
                ></base-input>
              </div>
              <div class="col-6">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('toolGroups.reservationType')}} *</label>
                <select v-model="toolGroupReservationType" class="form-control" :placeholder="$t('toolGroups.reservationType')">
                  <option
                    v-for="status in toolGroupReservationTypeOptions"
                    :value="status"
                    :label="status"
                  >
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-6"  v-if="this.toolGroupReservationType !== this.$t('toolGroups.reserve')">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.loanRentTo')}} *</label>
                <base-input
                :placeholder="$t('tools.loanRentTo')"
                v-model="toolGroupLoanRentExternalUser"
                ></base-input>
              </div>
              <div class="col-6"  v-if="this.toolGroupReservationType === this.$t('tools.rent')">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.dailyPrice')}}</label>
                <base-input
                :placeholder="$t('tools.dailyPrice')"
                v-model="toolGroupLoanRentDailyPrice"
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="startReserveToolGroup" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <template v-slot:table-select>
            <div>
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  v-for="(option, index) in filteredTablesCategories"
                  :key="option.name"
                  class="btn btn-sm btn-simple"
                  :class="[{ active: activeIndex === index}, selectClass]"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initTable(index)"
                    name="options"
                    autocomplete="off"
                    :checked="activeIndex === index"
                  />
                  <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div v-if="tableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myToolsTable.data"
                :tableColumns="myToolsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myToolsFrom + 1 }} {{ $t('pagination.to') }} {{ myToolsTo }} {{ $t('pagination.of') }} {{ myToolsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myToolsUpdatePage"
                class="pagination-no-border"
                v-model="myToolsPagination.currentPage"
                :per-page="myToolsPagination.perPage"
                :total="myToolsTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="externalReservationDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="externalReservationsTable.data"
                :tableColumns="externalReservationsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ externalReservationFrom + 1 }} {{ $t('pagination.to') }} {{ externalReservationTo }} {{ $t('pagination.of') }} {{ externalReservationTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="externalReservationUpdatePage"
                class="pagination-no-border"
                v-model="externalReservationPagination.currentPage"
                :per-page="externalReservationPagination.perPage"
                :total="externalReservationTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="carsTableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myCarsTable.data"
                :tableColumns="myCarsTable.columns"
                :allowClick="true"
                @row-click="goToCar"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myCarsFrom + 1 }} {{ $t('pagination.to') }} {{ myCarsTo }} {{ $t('pagination.of') }} {{ myCarsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myCarsUpdatePage"
                class="pagination-no-border"
                v-model="myCarsPagination.currentPage"
                :per-page="myCarsPagination.perPage"
                :total="myCarsTotal"
              >
              </base-pagination>
            </div>
          </div>

          <div v-if="toolGroupTableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myToolGroupsTable.data"
                :tableColumns="myToolGroupsTable.columns"
                :allowClick="true"
                @row-click="goToToolGroup"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myToolGroupsFrom + 1 }} {{ $t('pagination.to') }} {{ myToolGroupsTo }} {{ $t('pagination.of') }} {{ myToolGroupsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myToolGroupsUpdatePage"
                class="pagination-no-border"
                v-model="myToolGroupsPagination.currentPage"
                :per-page="myToolGroupsPagination.perPage"
                :total="myToolGroupsTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="reservationHistoryDataReady">
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="historySearchQuery"
                  aria-controls="datatables"
                  @keyup.native="searchReservationHistory"
                  @clear="handleClearSearchReservationHistory"
                >
                </el-input>
              </base-input>

            </div>
            <div class="table-responsive">
              <el-base-table
                :tableData="myReservationHistoryTable.data"
                :tableColumns="myReservationHistoryTable.columns"
                :enableEdit="true"
                :enableDelete="false"
                :actionsInline="true"
                :allowClick="true"
                @row-click="goToTool"
                @edit="handleReservationEdit"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myReservationHistoryFrom + 1 }} {{ $t('pagination.to') }} {{ myReservationHistoryTo }} {{ $t('pagination.of') }} {{ myReservationHistoryTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myReservationHistoryUpdatePage"
                class="pagination-no-border"
                v-model="myReservationHistoryPagination.currentPage"
                :per-page="myReservationHistoryPagination.perPage"
                :total="myReservationHistoryTotal"
              >
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import ElBaseTable from "../../components/ElBaseTable.vue";
import QrScanner from '@/components/QRScanner/QrScanner.vue'
import { BasePagination,Modal } from 'src/components';
import api from "../../services/api"
import {Select, Option,Table, TableColumn,Radio,Checkbox } from 'element-ui';
import swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export default {
    components: {
      'el-base-table': ElBaseTable,
      QrScanner,
      BasePagination,
      Modal,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      'el-checkbox': Checkbox,
      'el-radio': Radio
    },
    computed: {
      myToolsTo() {
        let highBound = this.myToolsFrom + this.myToolsPagination.perPage;
        if (this.myToolsTotal < highBound) {
          highBound = this.myToolsTotal;
        }
        return highBound;
      },
      myToolsFrom() {
        return this.myToolsPagination.perPage * (this.myToolsPagination.currentPage - 1);
      },
      myToolsTotal() {
        return this.myToolsPagination.total;
      },
      myCarsTo() {
        let highBound = this.myCarsFrom + this.myCarsPagination.perPage;
        if (this.myCarsTotal < highBound) {
          highBound = this.myCarsTotal;
        }
        return highBound;
      },
      myCarsFrom() {
        return this.myCarsPagination.perPage * (this.myCarsPagination.currentPage - 1);
      },
      myCarsTotal() {
        return this.myCarsPagination.total;
      },

      myToolGroupsTo() {
        let highBound = this.myToolGroupsFrom + this.myToolGroupsPagination.perPage;
        if (this.myToolGroupsTotal < highBound) {
          highBound = this.myToolGroupsTotal;
        }
        return highBound;
      },
      myToolGroupsFrom() {
        return this.myToolGroupsPagination.perPage * (this.myToolGroupsPagination.currentPage - 1);
      },
      myToolGroupsTotal() {
        return this.myToolGroupsPagination.total;
      },

      externalReservationTo() {
        let highBound = this.externalReservationFrom + this.externalReservationPagination.perPage;
        if (this.externalReservationTotal < highBound) {
          highBound = this.externalReservationTotal;
        }
        return highBound;
      },
      externalReservationFrom() {
        return this.externalReservationPagination.perPage * (this.externalReservationPagination.currentPage - 1);
      },
      externalReservationTotal() {
        return this.externalReservationPagination.total;
      },
      projectsTo() {
        let highBound = this.projectsFrom + this.projectsPagination.perPage;
        if (this.projectsTotal < highBound) {
          highBound = this.projectsTotal;
        }
        return highBound;
      },
      projectsFrom() {
        return this.projectsPagination.perPage * (this.projectsPagination.currentPage - 1);
      },
      projectsTotal() {
        return this.projectsPagination.total;
      },
      myReservationHistoryTo() {
        let highBound = this.myReservationHistoryFrom + this.myReservationHistoryPagination.perPage;
        if (this.myReservationHistoryTotal < highBound) {
          highBound = this.myReservationHistoryTotal;
        }
        return highBound;
      },
      myReservationHistoryFrom() {
        return this.myReservationHistoryPagination.perPage * (this.myReservationHistoryPagination.currentPage - 1);
      },
      myReservationHistoryTotal() {
        return this.myReservationHistoryPagination.total;
      },
      myTablesCategories() {
        return [{ name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings', showUser:true },
        { name: this.$t('projects.myReservationHistory'), icon: 'tim-icons icon-notes', showUser:true },
        { name: this.$t('cars.myCars'), icon: 'tim-icons icon-bus-front-12', showUser:true },
        { name: this.$t('toolGroups.myToolGroups'), icon: 'tim-icons icon-components', showUser:true },
        { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run', showUser:false }
      ];
      },
      filteredTablesCategories() {
        return this.myTablesCategories.filter(option => option.showUser || this.userRole !== 'User');
      },
      selectClass() {
        let darkMode = localStorage.getItem('darkMode');
        // If darkMode does not exist in localStorage, default it to 'false'
        if (darkMode === null) {
          darkMode = 'false';
        }
        if (darkMode === 'false') {
          return 'btn-standard';
        } else {
          return 'btn-primary';
        }
      }
    },
    data() {
      return {
        myToolsTable: {
          title: this.$t('tools.myTools'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'daysLeft', label: this.$t('tools.daysLeft'), minWidth: 60 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 60},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'common.project':'project', 'tools.daysLeft': 'daysLeft', 'tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        myCarsTable: {
          title: this.$t('cars.myCars'),
          columns: [
            { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
            { prop: 'licencePlate', label: this.$t('cars.licencePlate'), minWidth: 200 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.name': 'name', 'cars.licencePlate':'licencePlate','tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        myToolGroupsTable: {
          title: this.$t('toolGroups.myToolGroups'),
          columns: [
            { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
            { prop: 'toolGroupReservationType', label: this.$t('toolGroups.reservationType'), minWidth: 200 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'quantity', label: this.$t('toolGroups.quantity'), minWidth: 100 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.name': 'name','toolGroups.reservationType':'toolGroupReservationType','common.project':'project', 'toolGroups.quantity':'quantity','tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        externalReservationsTable: {
          title: this.$t('projects.toolsLoanedRentedOnProject'),
          columns: [
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'name', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'status', label: this.$t('tools.loanedRented'), minWidth: 80 },
            { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 90 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'name', 'common.status':'status', 'tools.loanedRentedTo': 'externalUser', 'tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        myReservationHistoryTable: {
          title: this.$t('projects.reservationHistory'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: false, isImage: true },
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'project', label: this.$t('projects.projects'), minWidth: 200},
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 100 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'projects.projects': 'project', 'tools.reserved': 'reserved', 'tools.returned': 'returned', 'common.totalAmount': 'totalPrice'}},
          ],
          data: [],
        },
        projects: [],
        projectTableColumns: [
          { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
          { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        ],
        myToolsInitialPage: true,
        myCarsInitialPage: true,
        projectsInitialPage: true,
        externalReservationInitialPage: true,
        myToolsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myReservationHistoryPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myCarsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myToolGroupsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        externalReservationPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        projectsPagination: {
          perPage: 5,
          currentPage: 1,
          total: 0
        },
        userId: '',
        tableDataReady: false,
        carsTableDataReady: false,
        toolGroupTableDataReady: false,
        externalReservationDataReady: false,
        reservationHistoryDataReady: false,
        showProjectPickModal:false,
        showUpdateReservationDaysModal: false,
        showReserveToolsInToolGroupModal: false,
        showReturnToolGroupModal: false,
        activeIndex: 0,
        cardTitle: this.$t('tools.myTools'),
        searchQuery: '',
        historySearchQuery: '',
        selectedProject: null,
        isMobile: window.innerWidth <= 768,
        currentQRContent: null,
        updateDays: '',
        latestUnreservedId: '',
        userRole: '',
        currentLatitude: '',
        currentLongitude: '',
        availableToolsInToolGroup: 'N/A',
        toolGroupQuantity: '',
        toolGroupLoanRentExternalUser: '',
        toolGroupLoanRentDailyPrice: '',
        toolGroupReservationType: this.$t('toolGroups.reserve'),
        reservingToolGroupInProgress: false,
        toolGroupReturnTable:[],
        toolGroupIdsToReturn: [],
        selectedToolGroupToolsToReturnRows: {},
        toolGroupReservationTypeOptions:[
          this.$t('toolGroups.reserve'),
          this.$t('tools.loan'),
          this.$t('tools.rent'),
        ],
        refreshHistory: false
      };
    },
    created() {
      window.addEventListener('resize', this.updateIsMobile);
      const token = Cookies.get('accessToken');
      const decodedToken = jwtDecode(token);
      this.userRole = decodedToken.role
      this.userId = decodedToken.UserIdentifier
      this.getCurrentLocation();
      this.fetchTableData();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateIsMobile);
    },
    methods: {
      searchProjects(event){
        this.projectsPagination.currentPage = 1;
        const queryLength = this.searchQuery.length;
        if (event.key === 'Backspace' || event.key === 'Enter') {
          this.fetchProjectData();
        }
        else {
          if (queryLength > 1) {
            if (queryLength % 2 === 0) {
              this.fetchProjectData();
            }
          }
        }
      },
      fetchProjectData() {
        let getProjectsUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        api.get(getProjectsUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      handleClearSearchProjects(){
        this.fetchProjectTableData();
      },
      handleClearSearchReservationHistory() {
        this.myReservationHistoryPagination.currentPage = 1;
        this.fetchHistoryTableData();
      },
      initTable(index) {
        // Note reserve and unreserve ToolGroups has the active index hardcoded
        // Needs to be updated at the same time so it does not break
        this.activeIndex = index;
        if (this.activeIndex === 0) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.toolGroupTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('tools.myTools');
          if (this.myToolsTable.data.length <= 0) {
            this.fetchTableData();
          }
          else {
            this.tableDataReady = true;
          }
        }
        else if (this.activeIndex === 1) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.toolGroupTableDataReady = false;
          this.tableDataReady = false;
          this.cardTitle = this.$t('projects.myReservationHistory');
          if (this.myReservationHistoryTable.data.length <= 0) {
            this.fetchHistoryTableData();
          }
          else {
            this.reservationHistoryDataReady = true;
          }
        }
        else if (this.activeIndex === 2) {
          this.tableDataReady = false;
          this.externalReservationDataReady = false;
          this.toolGroupTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('cars.myCars')
          if (this.myCarsTable.data.length <= 0) {
            this.fetchCarsData();
          }
          else {
            this.carsTableDataReady = true;
          }
        }
        else if(this.activeIndex === 3){
          this.tableDataReady = false;
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('toolGroups.myToolGroups')
          if (this.myToolGroupsTable.data.length <= 0) {
            this.fetchToolGroupsData();
          }
          else {
            this.toolGroupTableDataReady = true;
          }
        }
        else if(this.activeIndex === 4) {
          this.tableDataReady = false;
          this.carsTableDataReady = false;
          this.toolGroupTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          if (this.externalReservationsTable.data.length <= 0) {
            this.fetchExternalReservationData();
          }
          else {
            this.externalReservationDataReady = true;
          }
        }
      },
      handleReservationEdit(index, row) {
        swal.fire({
          title: this.$t('tools.updateReservedDays'),
          text: `${this.$t('tools.youHadToolFrom')} ${row.reserved} - ${row.returned} \n ${this.$t('common.totalAmount')} ${row.totalPrice} Kr.`,
          icon: 'success',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
            cancelButton: 'btn btn-success btn-fill'
          },
          confirmButtonText: this.$t('tools.updateReservedDays'),
          cancelButtonText: this.$t('delete.cancel'),
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.latestUnreservedId = row.reservationId;
            this.showUpdateReservationDaysModal = true;
            this.refreshHistory = true;
          }
        });
      },
      searchReservationHistory(event){
        this.myReservationHistoryPagination.currentPage = 1;
        const queryLength = this.historySearchQuery.length;
        if (event.key === 'Backspace' || event.key === 'Enter'|| Number.isInteger(Number(this.historySearchQuery))) {
          this.fetchHistoryTableData();
        }
        else {
          if (queryLength > 1) {
            if (queryLength % 2 === 0) {
              this.fetchHistoryTableData();
            }
          }
        }
      },
      async fetchHistoryTableData() {
        let toolReserveUrl = `/Tool/Reservations?currentPage=${this.myReservationHistoryPagination.currentPage}&pageSize=${this.myReservationHistoryPagination.perPage}&forCurrentUser=true&unreserved=true&OrderByDescending=true&OrderByReservedTimestamp=false`;
        if (this.historySearchQuery !== '') {
          toolReserveUrl += `&SearchString=${this.historySearchQuery}`;
        }
        try {
          const response = await api.get(toolReserveUrl);
          this.myReservationHistoryPagination.total = response.data.totalItems;
          this.myReservationHistoryTable.data = response.data.result.map(item => {
            return {
              toolId: item.toolId,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
              tool: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              project: item.project.name,
              totalDays: this.calculateDays(item.reservedTimestamp, item.unreservedTimestamp),
              'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              'returned': new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
              totalPrice: item.totalPrice,
              reservationId: item.id
            };
          });
          this.reservationHistoryDataReady = true;
        }
        catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
      },
      async fetchCarsData() {
        let toolGroupReserveUrl = `/ToolGroup/Reservations/?forCurrentUser=true&currentPage=${this.myCarsPagination.currentPage}&pageSize=${this.myCarsPagination.perPage}&toolGroupTypeId=1`
        api.get(toolGroupReserveUrl)
        .then(response => {
          this.myCarsPagination.total = response.data.totalItems;
          this.myCarsTable.data = response.data.result.map(item => {
            let licencePlate = ''
            if(item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
            {
              licencePlate = item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
            }
            return {
              toolGroupId: item.toolGroupId,
              name: item.toolGroup.name,
              licencePlate: licencePlate,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            };
          });
          this.carsTableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      async fetchTableData() {
        let toolReserveUrl = `/Tool/Reservations?forCurrentUser=true&currentPage=${this.myToolsPagination.currentPage}&pageSize=${this.myToolsPagination.perPage}`
        api.get(toolReserveUrl)
        .then(response => {
          this.myToolsPagination.total = response.data.totalItems;
          this.myToolsTable.data = response.data.result.map(item => {
            let daysLeft = ''
            if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
              const currentDate = new Date();
              const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
              const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
              daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
            }
            else{
              daysLeft = '∞'
            }

            return {
              reservationId: item.id,
              toolId: item.tool.id,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
              tool: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              project: item.project.name,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              daysLeft: daysLeft,
            };
          });
          this.tableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });

      },
      async fetchProjectTableData(){
        let projectUrl = ''
        if(this.searchQuery === '')
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&projectStatusId=2`
        }
        else
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        }
        api.get(projectUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      fetchToolGroupsData()
      {
        let toolGroupReserveUrl = `/ToolGroup/Reservations/?forCurrentUser=true&currentPage=${this.myToolGroupsPagination.currentPage}&pageSize=${this.myToolGroupsPagination.perPage}&toolGroupTypeId=2`
        api.get(toolGroupReserveUrl)
        .then(response => {
          this.myToolGroupsPagination.total = response.data.totalItems;
          this.myToolGroupsTable.data = response.data.result.map(item => {
            let reservationLoanRent = ""
            if(item.rented === true)
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Rented") + " - " + item.externalUser
            }
            else if(item.loaned === true)
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Loaned") + " - " + item.externalUser
            }
            else
            {
              reservationLoanRent = this.getReservationLoanRentTranslation("Reserved")
            }
            return {
              toolGroupId: item.toolGroupId,
              name: item.toolGroup.name,
              quantity: item.quantity,
              toolGroupReservationType: reservationLoanRent ,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              project: item.project ? item.project.name: ''
            };
          });
          this.toolGroupTableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });

      },
      async fetchExternalReservationData() {
        let exernalReservationUrl = `/Tool/Reservations/External?forCurrentUser=true&currentPage=${this.externalReservationPagination.currentPage}&pageSize=${this.externalReservationPagination.perPage}`
        api.get(exernalReservationUrl)
        .then(response => {
          this.externalReservationPagination.total = response.data.totalItems;
          this.externalReservationsTable.data = response.data.result.map(item => {
            let loanRent = item.rented ? this.$t('tools.rented') : loanRent = this.$t('tools.loaned');

            return {
              toolId: item.tool.id,
              name: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              externalUser: item.externalUser,
              status: loanRent,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            };
          });
          this.externalReservationDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      myToolsUpdatePage (item) {
        this.myToolsPagination.currentPage = item;
        this.fetchTableData();
      },
      projectsUpdatePage (item) {
        this.projectsPagination.currentPage = item;
        this.fetchProjectTableData();
      },
      myCarsUpdatePage (item) {
        this.myCarsPagination.currentPage = item;
        this.fetchCarsData();
      },
      myToolGroupsUpdatePage (item) {
        this.myToolGroupsPagination.currentPage = item;
        this.fetchToolGroupsData();
      },
      myReservationHistoryUpdatePage (item) {
        this.myReservationHistoryPagination.currentPage = item;
        this.fetchHistoryTableData();
      },
      externalReservationUpdatePage (item) {
        this.externalReservationPagination.currentPage = item;
        if (this.externalReservationInitialPage) {
          this.externalReservationInitialPage = false;
        }
        else {
          this.fetchExternalReservationData();
        }
      },
      goToTool(item) {
          this.$router.push({ name: 'Tool', params: { toolId: item.toolId } });
      },
      goToCar(item) {
        this.$router.push({ name: 'Car', params: { toolGroupId: item.toolGroupId.toString() } });
      },
      goToToolGroup(item) {
        this.$router.push({ name: 'ToolGroup', params: { toolGroupId: item.toolGroupId.toString() } });
      },
      async reserveTool(){
        if(this.selectedProject === null){
          this.$notify({
                message: this.$t('tools.pickProject'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
              return;
        }
        try {

          if(this.reservingToolGroupInProgress === true)
          {
            //reserve toolGroup
            await this.reserveToolGroup();
            this.reservingToolGroupInProgress = false;

          }
          else
          {
            //Normal tool
            const response = await api.post('/Tool/Reserve', {
              toolId: this.currentQRContent,
              projectId: this.selectedProject
            });
            this.$notify({
              message: this.$t('tools.toolReserved'),
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 1500,
            });
            this.fetchTableData();
            this.initTable(0)

          }
        }
        catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('tools.unableToReserveTool'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        }
        this.showProjectPickModal = false;
        this.selectedProject = null;
        this.searchQuery = ''

        this.projectsPagination= {
          perPage: 5,
          currentPage: 1,
          total: 0
        }

      },

      async reserveCar(carId) {
        const response = await api.post('/ToolGroup/Reserve', {
            toolGroupId: carId,
            userId: this.userId
          }).then(response => {
              this.$notify({
                  message: this.$t('cars.carReserved'),
                  icon: "tim-icons icon-check-2",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "success",
                  timeout: 1500,
              });
              this.fetchCarsData()
              this.initTable(2)

          })
          .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                  message: this.$t('common.unableToUpdate'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
              });
          });
        },

      async startReserve(qrContent){
        if(qrContent.includes("car"))
        {
           qrContent = qrContent.split("/").pop();
          this.currentQRContent = qrContent
           let statusUrl = `/Toolgroup/Info/${this.currentQRContent}`
          api.get(statusUrl)
          .then(response => {
            if(response.data.allActiveToolGroupReservations && response.data.allActiveToolGroupReservations.length > 0)
            {
              if(response.data.allActiveToolGroupReservations[0].user.id === this.userId)
              {
                this.$notify({
                  message: this.$t('cars.alreadyAssignedToYou'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "warning",
                    timeout: 0,
                });
                return;
              }

              let reservingUser = response.data.allActiveToolGroupReservations[0].user.firstName + ' ' + response.data.allActiveToolGroupReservations[0].user.lastName
              swal.fire({
                title: this.$t('cars.reservedByOtherUser') + ' ' + reservingUser,
                text: this.$t('cars.doYouWantToOvertake'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('cars.yesOvertake'),
                cancelButtonText: this.$t('delete.cancel'),
                buttonsStyling: false
              }).then(result => {
                if (result.value) {
                  api.put(`/ToolGroup/Unreserve/${this.currentQRContent}`).then(response => {
                      this.reserveCar(this.currentQRContent);
                  })
                  .catch(error => {
                      console.error('API request error:', error);
                      this.$notify({
                        message: this.$t('common.unableToUpdate'),
                          icon: "tim-icons icon-alert-circle-exc",
                          horizontalAlign: "center",
                          verticalAlign: "top",
                          type: "danger",
                          timeout: 0,
                      });
                    });
                }
              });
            }
            else
            {
              this.reserveCar(this.currentQRContent);
            }
          });
        }
        else if(qrContent.includes("toolGroup"))
        {
          qrContent = qrContent.split("/").pop();
          this.currentQRContent = qrContent
           let statusUrl = `/Toolgroup/Info/${this.currentQRContent}`
          api.get(statusUrl)
          .then(response => {
            if(response.data.toolGroupProperties.filter(p => p.key === 'totalCount'))
            {
              let totalQuantity = Number(response.data.toolGroupProperties.filter(p => p.key === 'totalCount')[0].value)
              this.availableToolsInToolGroup = totalQuantity - response.data.totalReservedQuantity
            }
            this.showReserveToolsInToolGroupModal = true;
          });
        }
        else
        {
          //validate QRContent
          qrContent = qrContent.split("/").pop();
          if(this.validateUuid(qrContent)){
            this.currentQRContent = qrContent
          }
          else{
            this.$notify({
              message: this.$t('tools.invalidToolId') + " " + this.currentQRContent,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
            return;
          }

          //Check info of tool
          let statusUrl = `/Tool/Info/${this.currentQRContent}`
          api.get(statusUrl)
          .then(response => {
              let needUserConfirmation = false
              let confirmationInfo = ''
              let confirmationQuestion = this.$t('tools.doYouWantToOvertake')
              let unreserve = false
              let externalUnreserve = false
              let takeOverCar = false
              if(response.data.isReserved === true){
                needUserConfirmation = true
                unreserve = true
                confirmationInfo = this.$t('tools.reservedByOtherUser') + ' ' + response.data.reservedBy
              }
              else if(response.data.isExternallyReserved === true){
                externalUnreserve = true
                needUserConfirmation = true
                confirmationInfo = this.$t('tools.loanedRentedByOtherUser') + ' ' + response.data.externallyReservedBy
              }
              else if(response.data.isInToolGroup === true){
                takeOverCar = true
                needUserConfirmation = true
                confirmationInfo = this.$t('tools.toolIsInCar') + ' ' + response.data.toolGroupName
                if(response.data.isToolGroupReserved){
                  confirmationInfo = confirmationInfo + ' ' + this.$t('tools.carReservedBy') + ' ' + response.data.toolGroupReservedBy
                }
              }

              if(needUserConfirmation === true){
                swal.fire({
                title: confirmationInfo,
                text: confirmationQuestion,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('tools.yesOvertake'),
                cancelButtonText: this.$t('delete.cancel'),
                buttonsStyling: false
              }).then(result => {
                if (result.value) {
                  let takeOverUrl = ''
                  if(unreserve === true){
                    takeOverUrl = `/Tool/Unreserve/${this.currentQRContent}`
                  }
                  else if(externalUnreserve === true){
                    takeOverUrl = `/Tool/Unreserve/External/${this.currentQRContent}`
                  }
                  else if(takeOverCar=== true){
                    this.fetchProjectTableData();
                    this.showProjectPickModal = true
                    return;
                  }

                  api.put(takeOverUrl).then(response => {
                    this.fetchProjectTableData();
                    this.showProjectPickModal = true
                  })
                  .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  }
                )
                .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  }

                );
                }
                else{
                  return;
                }
              });
              }
              else{
                this.fetchProjectTableData();
                this.showProjectPickModal = true
              }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
      },
      async unreserve(qrContent) {
        try {
          if(qrContent.includes("car"))
          {
            qrContent = qrContent.split("/").pop();
            this.currentQRContent = qrContent
            let statusUrl = `/Toolgroup/Info/${this.currentQRContent}`
            api.get(statusUrl)
            .then(response => {
              if(response.data.allActiveToolGroupReservations && response.data.allActiveToolGroupReservations.length > 0)
              {
                if(response.data.allActiveToolGroupReservations[0].user.id !== this.userId)
                {
                  this.$notify({
                    message: this.$t('cars.notAssignedToYou'),
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                  });
                  return;
                }
                 api.put(`/ToolGroup/Unreserve/${this.currentQRContent}`).then(response => {
                    this.$notify({
                      message: this.$t('cars.youReturned'),
                        icon: "tim-icons icon-alert-circle-exc",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success",
                        timeout: 1500,
                    });
                  this.fetchCarsData()
                  this.initTable(2)

                })
                .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: this.$t('common.unableToUpdate'),
                        icon: "tim-icons icon-alert-circle-exc",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                        timeout: 0,
                    });
                  });
              }
              else
              {
                this.$notify({
                  message: this.$t('cars.notAssignedToYou'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                });
              }
            });
          }
          else if(qrContent.includes("toolGroup"))
          {
            qrContent = qrContent.split("/").pop();
            let statusUrl = `/Toolgroup/Info/${qrContent}`
            api.get(statusUrl)
            .then(response => {
              if(response.data.activeToolGroupReservationsForCurrentUser === null || response.data.activeToolGroupReservationsForCurrentUser.length === 0)
              {
                this.$notify({
                  message: this.$t('errors.noToolGroupReserved'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
              }
              else
              {
                this.toolGroupReturnTable = response.data.activeToolGroupReservationsForCurrentUser
                .map(item => {
                let reservationLoanRent = ""
                if(item.rented === true)
                {
                  reservationLoanRent = this.getReservationLoanRentTranslation("Rented") + " - " + item.externalUser
                }
                else if(item.loaned === true)
                {
                  reservationLoanRent = this.getReservationLoanRentTranslation("Loaned") + " - " + item.externalUser
                }
                else
                {
                  reservationLoanRent = this.getReservationLoanRentTranslation("Reserved")
                }
                  return {
                    id: item.id,
                    toolGroupReservationType: reservationLoanRent,
                    quantity: item.quantity,
                    reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
                    project: item.project ? item.project.name: ''

                  };
                });

                this.showReturnToolGroupModal = true;
              }

            });
          }
          else
          {
            qrContent = qrContent.split("/").pop();
            if(this.validateUuid(qrContent))
            {
              //Check if current user has tool reserved
              let statusUrl = `/Tool/Info/${qrContent}`
              api.get(statusUrl)
              .then(response => {
                let errorMessage = ''
                const token = Cookies.get('accessToken');
                const decodedToken = jwtDecode(token);
                if(response.data.isReserved !== true){
                  errorMessage = this.$t('tools.toolNotReserved')
                }
                else if(response.data.userId !== this.userId){
                  errorMessage = this.$t('tools.reservedByDifferentUser') + " " + response.data.reservedBy
                }

                if(response.data.locationInfo.requirePresenceForToolReturn === true)
                {
                  const distance = this.calculateDistance(this.currentLatitude, this.currentLongitude, response.data.locationInfo.latitude, response.data.locationInfo.longitude);
                  if (distance > response.data.locationInfo.radiusMeters) {
                    errorMessage = this.$t('tools.toolNotAtLocation') + ': ' + response.data.locationInfo.address
                  }
                }

                if(errorMessage !== ''){
                  this.$notify({
                    message: errorMessage,
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                  });
                }
                else{
                  let unreserveUrl = `/Tool/Unreserve/${qrContent}`;
                  api.put(unreserveUrl).then(response => {
                    this.fetchTableData();
                    this.initTable(0)
                    //Check if user wants to override totalprice
                    if(response.data.totalPrice && response.data.totalPrice>0){
                      this.latestUnreservedId = response.data.id
                      let totalDays = this.calculateDays(response.data.reservedTimestamp, response.data.unreservedTimestamp);
                      swal.fire({
                      title: this.$t('tools.successfulUnreserve'),
                      text: `${this.$t('tools.youHadToolFor')} ${totalDays} ${this.$t('tools.daysPrice')} ${response.data.totalPrice} Kr.`,
                      icon: 'success',
                      showCancelButton: true,
                      customClass: {
                        confirmButton: 'btn btn-success btn-fill',
                        cancelButton: 'btn btn-danger btn-fill'
                      },
                      confirmButtonText: this.$t('tools.acceptTotalPrice'),
                      cancelButtonText: this.$t('tools.updateReservedDays'),
                      buttonsStyling: false
                      }).then(result => {
                      if (!result.value) {
                        this.showUpdateReservationDaysModal = true;
                      }
                      else{
                        this.$notify({
                          message: this.$t('tools.successfulUnreserve'),
                          icon: "tim-icons icon-check-2",
                          horizontalAlign: "center",
                          verticalAlign: "top",
                          type: "success",
                          timeout: 1500,
                        });
                      }
                      });
                    }
                    else{
                      this.$notify({
                        message: this.$t('tools.successfulUnreserve'),
                        icon: "tim-icons icon-check-2",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success",
                        timeout: 1500,
                      });
                    }
                  })
                  .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: this.$t('common.toolReturnError'),
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  });
                }
              })
              .catch(error => {
                console.error('API request error:', error);
                this.$notify({
                  message: this.$t('common.unableToRetrieveData'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
              });
            }
            else {
              this.$notify({
                message: this.$t('tools.invalidToolId') + ": " + toolId,
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
            }
          }

        }
        catch (error) {
          this.$notify({
            message: this.$t('tools.toolReturnError'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          console.error(error);
        }
      },
      unreserveToolGroup()
      {
        if(this.toolGroupIdsToReturn.length > 0)
        {
          const ids = this.toolGroupIdsToReturn.join(',');
          api.put(`/ToolGroup/UnreserveByIds/`,{ids: ids}).then(response => {
            this.showReturnToolGroupModal = false;
            this.$notify({
              message: this.$t('toolGroups.toolGroupReturned'),
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 1500,
            });
            this.initTable(3)
            this.fetchToolGroupsData();
          });
        }

        else
        {
          this.$notify({
            message: this.$t('errors.nothingSelected'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
      },
      validateUuid(uuid) {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(uuid);
      },
      updateReservationDays(){
        if (!Number.isInteger(Number(this.updateDays)) || this.updateDays === '') {
          this.$notify({
            message: this.$t('common.needsToBeNumber'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          return;
        }

        api.put(`/Tool/Reservation/${this.latestUnreservedId}`,{days: this.updateDays}).then(response => {
          this.showUpdateReservationDaysModal = false;
          this.updateDays = ''
          swal.fire({
            title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
          if (this.refreshHistory) {
            this.fetchHistoryTableData();
            this.refreshHistory = false;
          }
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });

      },
      startReserveToolGroup()
      {
        if(!this.validateToolGroupReserveInput())
        {
          return;
        }
        swal.fire({
          title: this.$t('projects.connectToProject') + "?",
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: this.$t('common.yes'),
          cancelButtonText: this.$t('common.no'),
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.showReserveToolsInToolGroupModal = false;
            this.reservingToolGroupInProgress = true;
            this.fetchProjectTableData();
            this.showProjectPickModal = true
          }
          else{
            this.reserveToolGroup();
          }
        });
      },
      reserveToolGroup()
      {
        api.post('/ToolGroup/Reserve', {
          toolGroupId: Number(this.currentQRContent),
          userId: this.userId,
          externalUser: this.toolGroupLoanRentExternalUser,
          quantity: Number(this.toolGroupQuantity),
          loaned: this.toolGroupReservationType === this.$t('tools.loan'),
          rented: this.toolGroupReservationType === this.$t('tools.rent'),
          dailyPrice: this.toolGroupLoanRentDailyPrice !== '' ? Number(this.toolGroupLoanRentDailyPrice): null,
          projectId: this.selectedProject
        }).then(response =>{
          this.$notify({
            message: this.$t('toolGroups.toolGroupReserved'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.initTable(3)
          this.fetchToolGroupsData();

            //clean up propertes from modal:
          this.toolGroupLoanRentDailyPrice = ''
          this.toolGroupReservationType = this.$t('toolGroups.reserve');
          this.toolGroupQuantity = '',
          this.toolGroupLoanRentExternalUser = ''
        }).catch(error => {
          console.error(error)
          this.$notify({
            message: this.$t('toolGroups.unableToReserveToolGroup'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
        this.showReserveToolsInToolGroupModal = false;
      },

      validateToolGroupReserveInput()
      {
        let errorMessage = ""
        let valid = true
        if(this.toolGroupReservationType !== this.$t('toolGroups.reserve') && this.toolGroupLoanRentExternalUser === '')
        {
          errorMessage = this.$t('errors.externalUserEmpty')
          valid = false;
        }
        else if(this.toolGroupQuantity === '' || isNaN(this.toolGroupQuantity))
        {
          errorMessage = this.$t('errors.totalCountEmpty')
          valid = false;
        }
        else if(Number(this.toolGroupQuantity) > Number(this.availableToolsInToolGroup))
        {
            errorMessage = this.$t('errors.quantityNotAvailable');
            valid = false;
        }

        else if(this.toolGroupReservationType === this.$t('tools.rent'))
        {
          if(this.toolGroupLoanRentDailyPrice !== '' && isNaN(this.toolGroupLoanRentDailyPrice))
          {
            errorMessage = this.$t('errors.dailyPriceNotNumber');
            valid = false;
          }
        }

        if(errorMessage !== '')
        {
          this.$notify({
            message: errorMessage,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
        return valid;

      },
      handleSelection(id, checked) {
        if (checked) {
          if (!this.toolGroupIdsToReturn.includes(id)) {
            this.toolGroupIdsToReturn.push(id);
          }
        } else {
          // Remove ID from the array if it's unchecked
          this.toolGroupIdsToReturn = this.toolGroupIdsToReturn.filter(item => item !== id);
        }
      },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.currentLatitude = position.coords.latitude;
            this.currentLongitude = position.coords.longitude;
          }, error => {
            console.error(error);
          });
        } else {
            this.$notify({
              message: "Geolocation is not supported by this browser.",
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "warning",
              timeout: 0,
            });
        }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // Radius of the Earth in meters
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // in meters
        return distance;
      },
    calculateDays(date1, date2) {
      var date1_ms = new Date(date1).getTime();
      var date2_ms = new Date(date2).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
    },
    getReservationLoanRentTranslation(reservationLoanRent)
    {
       switch (reservationLoanRent) {
            case "Loaned":
              return this.$t('tools.loaned')
            case "Rented":
              return this.$t('tools.rented')
            case "Reserved":
              return this.$t('tools.reserved')
            default:
              return 'N/A'
          }
    },
  },
};
</script>
<style scoped>
.unreserve-tools {
    display: flex;
    align-items: center;
    gap: 10px;
}
.el-radio__label{
  display: none !important;
}
.toolgroup-modal-label {
  padding-left: 0px;
}
.modal-scroll-fix{
  overflow-y: auto;
}
</style>
